
import './variables.scss';
import './fonts.scss';
import './bruecks.scss'

import { jarallax, jarallaxVideo } from "jarallax";

console.log("child theme loaded ");


/* init Jarallax */
jarallax(document.querySelectorAll('.jarallax'), {
  speed: 0.5,
  imgWidth: 1366,
  imgHeight: 768
});


// if ($(".modal-backdrop").hasClass("show")) {
//     console.log("ja");
// } else {
//   
//   console.log("nein");
// }


$(window).on('load', function () {
  
  // $('#userWidget').on('DOMSubtreeModified', function(){
  //   if ($( ".user-card" ).length) {
  //     $(".login-placeholder-svg").css("display", "none");
  //   } else {
  //     $(".login-placeholder-svg").css("display", "inline-block");
  //   }
  // });
  
  $(".main-content").on('click', function(event){
      if ($(".modal-backdrop").hasClass("show") && $("#cropper-modal").is(":visible")) {
        $(".main-content").css("z-index", "9999");
      } else {
        $(".main-content").css("z-index", "1");
      }
  });
  
});